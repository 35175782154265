import cookie from 'react-cookies';

export const decodeResponse = (response) => {
  const parts = response.split('.');
  if (parts.length !== 3) {
    throw new Error(
        'Auth token must have 3 parts; ' + parts.length + ' provided.',
    );
  }
  return {
    header: JSON.parse(_base64UrlDecode(parts[0])),
    payload: JSON.parse(_base64UrlDecode(parts[1])),
    signature: _base64UrlDecode(parts[2]),
  };
};

export const checkEmailValidity = (email) => {
  const validEmailRegex =new RegExp(
      '^([a-zA-Z0-9_.+-]+)@([a-zA-Z0-9_.-]+)\\.([a-zA-Z]{2,5})$',
  );
  return validEmailRegex.test(email);
};

/**
   *
   *
   * @param {*} base64UrlString
   * @return {*} decoded base64Url String
   */
function _base64UrlDecode(base64UrlString) {
  let base64String = base64UrlString.replace(/-/g, '+').replace(/_/g, '/');

  switch (base64String.length % 4) {
    case 0: {
      break;
    }
    case 2: {
      base64String += '==';
      break;
    }
    case 3: {
      base64String += '=';
      break;
    }
    default: {
      throw new Error('Illegal base64url string.');
    }
  }

  return window.atob(base64String);
}

export const setSession = (token) => {
  const id = token.payload.sub;
  const expires = new Date;
  expires.setFullYear(expires.getFullYear() + 2);
  cookie.save('shawacademy_leadId', id,
      {path: '/', domain: process.env.CookieDomain, expires});
};

export const isLoggedIn = (firstName) => {
  console.log('COOKIE DOMAIN HERE: ', process.env.CookieDomain);
  const leadId = cookie.load('shawacademy_leadId');
  if (!leadId) return '';
  const expires = new Date;
  expires.setFullYear(expires.getFullYear() + 2);
  cookie.save('shawacademy_leadId', leadId,
      {path: '/', domain: process.env.CookieDomain, expires});
  if (firstName) {
    cookie.save('student_name', firstName,
        {path: '/', domain: process.env.CookieDomain});
  }
  return leadId;
};

export const logout = () => {
  cookie.remove('shawacademy_leadId', {path: '/'});
  cookie.remove('shawacademy_name', {path: '/',
    domain: process.env.CookieDomain});
  cookie.remove('student_name', {path: '/',
    domain: process.env.CookieDomain});
  cookie.remove('shawacademy_leadId', {path: '/',
    domain: process.env.CookieDomain});
  cookie.remove('auth_token', {path: '/', domain: process.env.CookieDomain});
  cookie.remove('country');
  sessionStorage.clear();
};

export const getParameterByName = (name, url) => {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
